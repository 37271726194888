import {
  Footer,
  Header,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  Text,
  PixelIcon,
  Spacer,
  Input,
} from "nes-ui-react";

export const SecretCodeModal = ({ onClose }: { onClose(): void }) => {
  return (
    <Modal open={true} onClose={onClose} className="w-[400px]">
      <Header>
        <Spacer />
        <Heading dense>Did you find the secret code?</Heading>
        <Spacer />
        <IconButton color="error" size="small" onClick={onClose}>
          <PixelIcon name="pixelicon-close" size="small" />
        </IconButton>
      </Header>
      <ModalContent>
        <Text>Enter code:</Text>
        <div>
          <Input className="h-[40px]" type="text" />
        </div>
      </ModalContent>
      <Footer>
        <IconButton color="error" onClick={onClose}>
          <PixelIcon name="pixelicon-close" size="small" />
          <Text size="small">Cancel</Text>
        </IconButton>
        <Spacer />
        <IconButton color="success" onClick={onClose}>
          <Text size="small">Submit code</Text>
          <PixelIcon name="pixelicon-checkmark" size="small" />
        </IconButton>
      </Footer>
    </Modal>
  );
};

import { Container, Text } from "nes-ui-react";
import StreamGif from "./stream.gif";
import Sound from "react-sound";

import React from "react";

export const Stream = ({ connected }: { connected?: boolean }) => {
  return (
    <Container
      className="flex-1 text-start px-10"
      title="Live Stream"
      roundedCorners
    >
      <div className="h-full flex items-center justify-center bg-black mx-[-30px] overflow-hidden">
        <div className={connected ? undefined : "blur-xl"}>
          <img src={StreamGif} />
        </div>

        {!connected && (
          <div className="absolute">
            <Text size="large">Connect with Metamask to watch the stream!</Text>
          </div>
        )}
      </div>

      {/*  {connected ? (
        <div className="h-full flex items-center justify-center bg-black mx-[-30px]">
          <img src={StreamGif} />
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Text>Connect with Metamask to watch the stream!</Text>
        </div>
      )} */}
    </Container>
  );
};

const CryptoJS = require("crypto-js");

export function getRandomNumber(seedString: string): number {
  // Hash the seed string using SHA-256
  const hash = CryptoJS.SHA256(seedString);
  // Convert the hash to a big integer (taking the first 16 characters to avoid precision issues)
  const bigIntHash = parseInt(
    hash.toString(CryptoJS.enc.Hex).substring(0, 16),
    16
  );
  // Generate the random number between 0 and 34
  return bigIntHash % 35;
}

export const getRandomGreeting = () => {
  const greetings = [
    "Hello dear, welcome to the chat. Glad to see you have chosen not be a sideliner anymore.",
    "Welcome child, feel like aping today?",
    "Dear? Is that you? Didn't hear you knock, with your paper hands.",
    "We survived another day haven't we? How about you load up on some GRNDMA to celebrate?",
    "Lovely day to you darling, and remember what GRNDMa always told you: Don't get rekt!",
  ];
  const randomIndex = Math.floor(Math.random() * 5);
  return greetings[randomIndex];
};

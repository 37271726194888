import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";

interface IAuthContext {
  accessToken: string | null;
  login: (accessToken: string) => void;
  logout: () => void;
  loading: boolean;
  isTokenValid: boolean;
  name?: string;
  picture?: string;
}

const AuthContext = createContext<IAuthContext>({
  accessToken: null,
  login: () => {},
  logout: () => {},
  loading: true,
  isTokenValid: false,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );

  const [loading, setLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      setAccessToken(accessToken);
      setIsTokenValid(checkTokenValidity(accessToken));
    }
    setLoading(false);
  }, []);

  const checkTokenValidity = (accessToken: string) => {
    try {
      const decodedToken: any = jwtDecode(accessToken);
      return true;
    } catch (error) {
      logout();
      return false;
    }
  };

  const login = (accessToken: string) => {
    setAccessToken(accessToken);
    setIsTokenValid(checkTokenValidity(accessToken));
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("credentials");
    setAccessToken(null);
    setIsTokenValid(false);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        login,
        logout,
        loading,
        isTokenValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => useContext(AuthContext);

import { Container, Text, Input, Button } from "nes-ui-react";
import { SecretCodeModal } from "./SecretCodeModal";
import { useState } from "react";

export const SecretCode = ({ connected }: { connected?: boolean }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container roundedCorners>
      {connected ? (
        <>
          <div className="flex items-center justify-center">
            <Text>Did you find the secret code?</Text>
            <div>
              <Button
                size="large"
                className="ml-4"
                onClick={() => setShowModal(true)}
              >
                Enter secret code
              </Button>
            </div>
          </div>
          {showModal && <SecretCodeModal onClose={() => setShowModal(false)} />}
        </>
      ) : (
        <div>Connect Metamask to enter secret code.</div>
      )}
    </Container>
  );
};

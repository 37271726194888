import { API_URL } from "../config";

export const metamaskSignIn = async (
  walletAddress: string,
  signedChallenge: string
) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      signed_challenge: signedChallenge,
    }),
  };
  try {
    const fetchResponse = await fetch(`${API_URL}/metamask-sign-in`, settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const getChallenge = async () => {
  try {
    const fetchResponse = await fetch(`${API_URL}/challenge`, {
      method: "GET",
    });
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const sendMessage = async (message: string, token?: string) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      message,
    }),
  };
  try {
    await fetch(`${API_URL}/message`, settings);
    return true;
  } catch (e) {
    throw e;
  }
};

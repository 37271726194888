import { GRNDMA_CONTRACT_ADDRESS } from "../constants/addresses";

export const getBalance = async (account: string) => {
  if (window.ethereum && !!account) {
    try {
      const methodId = "0x70a08231"; // Method ID for balanceOf(address)
      const addressPadded = account
        .toLowerCase()
        .replace(/^0x/, "")
        .padStart(64, "0"); // Ensure the address is correctly padded

      const data = methodId + addressPadded;

      const params = [
        {
          to: GRNDMA_CONTRACT_ADDRESS,
          data: data,
        },
      ];

      const balanceHex = await window.ethereum.request({
        method: "eth_call",
        params: params,
      });

      //@ts-ignore
      const balance = parseInt(balanceHex, 16) / Math.pow(10, 18); // Adjust for token's decimals

      return balance;
    } catch (error) {
      console.error("Error:", error);
    }
  } else {
    console.log("MetaMask is not installed!");
    return 0;
  }
};

import { Container, List, Text } from "nes-ui-react";

export const FAQ = () => {
  return (
    <Container className="flex-1 text-start px-10 " title="FAQ" roundedCorners>
      <div className=" min-h-[500px] ">
        <List styleType="circle">
          <li>What is GRNDMA?</li>
          <Text size="small" color="silver" className="mb-2">
            GRNDMA token allows you to talk to GRNDMA, tapping into her
            bottomless barell of crypto and life wisdom. It gives you access to
            GRNDMAs trivia games, price predictions, views on the market and
            general life advice. Owners of GRNDMA become part of exclusive
            community of meme coin enthusiasts.
          </Text>
          <br />
          <br />
          <li>How do I get GRNDMA?</li>
          <Text size="small" color="silver" className="mb-2">
            To get your hands on GRNDMA you can participate in the Super-early
            GRNDMA airdrop, win GRNDMAs trivia games or straight up buy it here.
          </Text>
          <br />
          <br />
          <li>Can anyone get the Super-early airdrop?</li>
          <Text size="small" color="silver">
            No! Super early airdrop is reserved for first 5000 people that
            apply. Hurry up!
          </Text>
          <br />
          <br />
          <li>How high will GRNDMA go?</li>
          <Text size="small" color="silver" className="mb-2">
            Nobody really knows but GRNDMA is programmed 2 USD.
          </Text>
          <br />
          <br />
          <li>Is this just another MEME coin?</li>
          <Text size="small" color="silver">
            Yes, but you can talk to it, learn stuff and make friends.
          </Text>
          <br />
          <br />
          <li>Really, what is the value of a GRNDMA token?</li>
          <Text size="small" color="silver">
            1GRNDMA = 1GRNDMA
          </Text>
          <br />
          <br />
          <li>Is the sign-up free?</li>
          <Text size="small" color="silver">
            Absolutely! Anyone can connect their wallet and join the party!
          </Text>
        </List>
      </div>
    </Container>
  );
};

import { Button, Container, Input, Text, Toast } from "nes-ui-react";
import { useSDK } from "@metamask/sdk-react";
import metamaskLogo from "../metamask.png";
import { ChatToolbar } from "./ChatToolbar";
import grandmaLogo from "../grandma.png";
import Pusher from "pusher-js";
import React from "react";
import { userColors } from "./constants";
import { getRandomNumber, getRandomGreeting } from "./helpers";
import { generateRandomName } from "./randomNames";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  names,
  animals,
} from "unique-names-generator";
import { sendMessage } from "../api/api";
import { useAuth } from "../hooks/useAuth";

const pusher = new Pusher(
  "fdb93b52c63aec3d7f0e", //@ts-ignore
  {
    cluster: "eu",
  }
);
var channel = pusher.subscribe("my-channel");

interface Message {
  user: string;
  message: string;
}

const Message = ({ user, message }: { user: string; message: string }) => {
  const color = userColors[getRandomNumber(user)];

  return (
    <div className="text-left flex gap-1 message">
      <Text size="small">
        <b className={color}>{user}: </b>
      </Text>
      <Text size="small">{message}</Text>
    </div>
  );
};

export const TalkToGRNDMA = ({ connected }: { connected: boolean }) => {
  const messageBoxFref = React.createRef<HTMLDivElement>();

  const [messageText, setMessageText] = React.useState("");
  const [messages, setMessages] = React.useState<Message[]>([
    {
      user: "GRNDMA",
      message: getRandomGreeting(),
    },
  ]);

  const handleNewMessage = async (data: any) => {
    setMessages([...messages, { user: data.user, message: data.message }]);
    const element = messageBoxFref.current;

    await setTimeout(() => {
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  };

  React.useEffect(() => {
    channel.unbind_all();
    channel.bind("my-event", handleNewMessage);
  });

  const { accessToken } = useAuth();

  const handleSend = () => {
    if (accessToken) {
      sendMessage(messageText, accessToken);
      setMessageText("");
    }
  };

  return (
    <Container title="GRNDMA Chat" className="flex-1" roundedCorners>
      <div className="flex flex-col w-full min-h-[500px]  ">
        {connected ? (
          <>
            {/*  <ChatToolbar /> */}
            {/*    <div className="flex">
              <img src={grandmaLogo} width={60} className="mr-3" />
              <Toast>
                <Text>Hey buddy, how are you?</Text>
              </Toast>
            </div> */}

            <div
              ref={messageBoxFref}
              className="flex flex-col flex-1 items-start gap-7 max-h-[500px] overflow-auto"
            >
              {messages.map((msg, index) => (
                <Message
                  key={`${msg.user}${index}`}
                  user={msg.user}
                  message={msg.message}
                />
              ))}
            </div>

            <div className="mt-auto w-full flex items-center">
              <div className="flex-1">
                <Input
                  className="h-[40px]"
                  type="text"
                  color="warning"
                  value={messageText}
                  onChange={(value) => setMessageText(value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSend();
                    }
                  }}
                />
              </div>
              <div>
                <Button size="large" className="ml-3" onClick={handleSend}>
                  Send{" >"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex w-full h-[500px] flex-col items-center justify-center">
            <Text>Connect with Metamask to talk to GRNDMA!</Text>
          </div>
        )}
      </div>
    </Container>
  );
};

import { TalkToGRNDMA } from "./TalkToGRNDMA";
import { Stream } from "./Stream/Stream";
import { SecretCode } from "./SecretCode";
import { Airdrop } from "./Airdrop";
import metamaskLogo from "../metamask.png";
import grandmaLogo from "../grandma.png";
import { getBalance } from "../Web3/getBalance";
import { getAccounts } from "../Web3/getAccounts";
import { useEffect, useState } from "react";
import { getChallenge, metamaskSignIn } from "../api/api";
import { Button, Text } from "nes-ui-react";
import { useSDK } from "@metamask/sdk-react";
import { useAuth } from "../hooks/useAuth";
import grooveMusic from "./groove.mp3";
import useSound from "use-sound";
import { FAQ } from "./FAQ";

export const LandingPage = () => {
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<number>();

  const [play, { stop }] = useSound(grooveMusic, {
    volume: account ? 0.03 : 0,
    loop: true,
  });

  const { sdk, connected: isConnected } = useSDK();

  const { isTokenValid, login, logout } = useAuth();

  const connected = isTokenValid && !!isConnected && !!account;

  const connect = async () => {
    try {
      const {
        data: { challenge },
      } = await getChallenge();

      await sdk?.disconnect();
      setAccount(undefined);
      logout();

      const signedResult = await sdk?.connectAndSign({ msg: challenge || "" });
      const accounts = await getAccounts();

      //@ts-ignore
      const theAccount = accounts?.[0];

      if (theAccount) {
        const { token } = await metamaskSignIn(
          theAccount,
          String(signedResult)
        );

        login(token);
      }

      stop();
      play();

      //@ts-ignore
      setAccount(theAccount);
    } catch (err) {
      console.warn("failed to connect..", err);
    }
  };

  const disconnect = async () => {
    try {
      await sdk?.disconnect();
      logout();
    } catch (err) {
      console.warn("failed to connect..", err);
    } finally {
      setAccount(undefined);
    }
  };

  useEffect(() => {
    const fetchBalance = async (account: string) => {
      const result = await getBalance(account || "");
      setBalance(result || 0);
    };

    if (connected && account) {
      fetchBalance(account);
    }
  }, [connected, account]);

  return (
    <div className="App">
      <div className="  sticky top-0 z-10 bg-[#000]">
        <div className="px-10 py-4  w-full flex items-center mx-auto max-w-[1300px] ">
          <div className="flex">
            <img src={grandmaLogo} width={30} className="mr-3" />
            <div style={{ fontSize: 30 }}>GRNDMA</div>
          </div>

          {!connected ? (
            <Button
              className="ml-auto"
              size="large"
              color="primary"
              onClick={connect}
            >
              <div className="flex items-center">
                <img src={metamaskLogo} width={24} />
                <Text>Connect Metamask</Text>
              </div>
            </Button>
          ) : (
            <div className="ml-auto flex items-center">
              {account && (
                <div className="flex flex-col mr-4 gap-2">
                  <div className="mr-3">
                    <div className="flex">
                      <Text className="font-black font-lg mr-1">Account:</Text>
                      <Text color="lightGray">{`${account.slice(
                        0,
                        7
                      )}...${account.slice(-5)}`}</Text>
                    </div>
                  </div>
                  <div className="flex">
                    <Text className="mr-1">Balance:</Text>
                    <div className="flex">
                      <Text color="lightGreen">{balance}</Text>
                      <Text className="ml-1" color="lightGray">
                        GRNDMA
                      </Text>
                    </div>
                  </div>
                </div>
              )}
              <Button size="large" color="success" onClick={disconnect}>
                <div className="flex items-center">
                  <img src={metamaskLogo} width={24} />
                  <Text color="darkGreen">Disconnect</Text>
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="h-screen bg-[#212529]">
        <div className="w-full items-start flex flex-col max-w-[1300px] mx-auto px-10 py-4">
          <Airdrop />
          <div className="flex w-full">
            <Stream connected={connected} />
            {connected ? <TalkToGRNDMA connected={connected} /> : <FAQ />}
          </div>
          {/*      <Airdrop /> */}
          <div className="w-full">
            <SecretCode connected={connected} />
          </div>
          {connected && (
            <div className="scale-150 mb-40">
              {/*   <Button onClick={test}>SEND</Button> */}
              {/*   <Button size="large" onClick={test}>
          Send GRNDMA tokens
        </Button> */}
              {/*  <img src={grandmaLogo} width={100} className="mr-3" /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const userColors = [
  "text-red-400",
  "text-green-400",
  "text-purple-400",
  "text-pink-400",
  "text-yellow-400",
  "text-blue-400",
  "text-brown-400",
  "text-cyan-400",
  "text-sky-400",
  "text-indigo-400",
  "text-fuchsia-400",
  "text-rose-400",
  "text-teal-400",
  "text-emerald-400",
  "text-amber-400",
  "text-lime-400",
  "text-orange-400",
  "text-red-600",
  "text-green-600",
  "text-purple-600",
  "text-pink-600",
  "text-yellow-600",
  "text-blue-600",
  "text-brown-600",
  "text-cyan-600",
  "text-sky-600",
  "text-indigo-600",
  "text-fuchsia-600",
  "text-rose-600",
  "text-teal-600",
  "text-emerald-600",
  "text-amber-600",
  "text-lime-600",
  "text-orange-600",
];

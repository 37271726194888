import { LandingPage } from "./Components/LandingPage";
import "./App.css";

import "./index.css";

import { AuthProvider } from "./hooks/useAuth";

function App() {
  return (
    <AuthProvider>
      <LandingPage />
    </AuthProvider>
  );
}

export default App;

import { Container, Text } from "nes-ui-react";

export const Airdrop = () => {
  return (
    <Container
      title="Super-early GRNDMA Airdrop"
      roundedCorners
      className="w-full "
    >
      <div className="flex justify-between gap-3">
        <div>
          <Text color="#92cc41" size="xlarge">
            Step 1
          </Text>
          <Text className="max-w-[250px]">
            Connect with Metamask wallet to see the GRNDMA live stream.
          </Text>
        </div>
        <div>
          <Text color="#92cc41" size="xlarge">
            Step 2
          </Text>
          <Text className="max-w-[250px] ">
            Kindly ask{" "}
            <b>
              <span className="text-purple-400 text-xl">@GRNDMA </span>
            </b>
            for <br /> a secret code.
          </Text>
        </div>
        <div>
          <Text color="#92cc41" size="xlarge">
            Step 3
          </Text>
          <Text className="max-w-[250px]">
            Enter the secret code into the field and get boatload of GRNDMA!
          </Text>
        </div>
      </div>
    </Container>
  );
};
